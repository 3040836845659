import React, { Component } from "react";
import { defaults, Line, Chart } from "react-chartjs-2";
import { Loader, Segment } from "semantic-ui-react";
import { formatSize } from "../utils/formatSize";

defaults.global.devicePixelRatio = 2;
defaults.global.animation = false;

const chartColors = [
  "rgba(56, 137, 234, 1)", //putre1
  "rgba(206, 222, 237, 1)", //putre2
  "rgba(54, 162, 235, 1)", //blue
  "rgba(255, 99, 132, 1)", // red
  "rgba(255, 159, 64, 1)", //orange
  "rgba(255, 205, 86, 1)", //yellow
  "rgba(75, 192, 192, 1)", //green
  "rgba(153, 102, 255, 1)", //purple
  "rgba(231,233,237, 1)" //grey
];

class LineChart extends Component {
  componentWillMount() {
    Chart.plugins.register({
      afterDatasetsDraw: function(chart) {
        if (chart.tooltip._active && chart.tooltip._active.length) {
          var activePoint = chart.tooltip._active[0],
            ctx = chart.ctx,
            y_axis = chart.scales["y-axis-0"],
            x = activePoint.tooltipPosition().x,
            topY = y_axis.top,
            bottomY = y_axis.bottom;
          // draw line
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 1;
          ctx.strokeStyle = "#CCCCCC08";
          ctx.stroke();
          ctx.restore();
        }
      }
    });
  }

  render() {
    const loading = this.props.data.length === 0;
    const data = {
      datasets: this.props.datasets.map((d, index) => {
        return {
          label: d.label,
          data: this.props.data.map(i => {
            return { x: i.x, y: d.dataCallback(i.item) };
          }),
          maintainAspectRatio: true,
          type: "line",
          responsive: true,
          lineTension: 0,
          pointRadius: 0,
          pointHoverRadius: 3,
          pointHitRadius: 10,
          borderWidth: 1,
          backgroundColor: chartColors[index].replace(/1\)/, "0.2)"),
          borderColor: chartColors[index],
          pointBackgroundColor: chartColors[index]
        };
      })
    };

    const options = {
      hover: {
        mode: "index",
        intersect: false
      },
      tooltips: {
        mode: "index",
        intersect: false,
        callbacks: {
          label: (tooltipItem, data) => {
            var label = data.datasets[tooltipItem.datasetIndex].label || "";
            if (this.props.type === "size") {
              return `${label}: ${formatSize(tooltipItem.yLabel)}`;
            }

            return `${label}: ${tooltipItem.yLabel}`;
          }
        }
      },
      scales: {
        xAxes: [
          {
            type: "time",
            distribution: "linear",
            time: {
              unit: "week"
            },
            ticks: {
              source: "data",
              autoSkip: true
            }
          }
        ],
        yAxes: [
          {
            stacked: this.props.stacked || false,
            scaleLabel: {
              display: true,
              labelString: this.props.yLabel
            },
            ticks: {
              beginAtZero: true,
              callback: value => {
                if (this.props.type === "size") {
                  return `${formatSize(value)}`;
                }

                return value;
              }
            }
          }
        ]
      }
    };

    return (
      <div>
        {loading ? (
          <Segment>
            <Loader active />
          </Segment>
        ) : (
          <Line data={data} options={options} />
        )}
      </div>
    );
  }
}

export default LineChart;
