import React, { Component } from "react";
import "semantic-ui-css/semantic.min.css";
import {
  Button,
  Container,
  Form,
  Grid,
  Header,
  Loader,
  Menu,
  Segment,
  Statistic
} from "semantic-ui-react";
import LineChart from "./charts/LineChart";
import { formatSize } from "./utils/formatSize";
import { loadCloudflareStatsData, loadNativeStatsData } from "./utils/loadData";

const style = {
  header: {
    margin: "1em 0em 1em"
  }
};

const defaultViewDays = 1;
const defaultViewStep = 1 / 24;

class App extends Component {
  constructor(props) {
    super(props);

    const authKey = localStorage.getItem("putre-panel-authKey");

    if (authKey) {
      this.state = {
        authKey: authKey,
        loggedIn: true,
        cloudflareData: [],
        cloudflareTotals: {},
        nativeData: [],
        nativeTotals: {},
        days: 0
      };
    } else {
      this.state = {
        authKey: "",
        loggedIn: false,
        cloudflareData: [],
        cloudflareTotals: {},
        nativeData: [],
        nativeTotals: {},
        days: 0
      };
    }

    this.onSubmit = this.onSubmit.bind(this);
    this.onAuthKeyChange = this.onAuthKeyChange.bind(this);
    this.onLogout = this.onLogout.bind(this);
  }

  refreshData(days, stepDays) {
    this.setState({
      ...this.state,
      nativeData: [],
      nativeTotals: {},
      cloudflareData: [],
      cloudflareTotals: {},
      days: days
    });

    loadCloudflareStatsData(this.state.authKey, days).then(data => {
      this.setState({
        ...this.state,
        cloudflareData: data.timeseries,
        cloudflareTotals: data.totals
      });
    });

    loadNativeStatsData(this.state.authKey, days, stepDays).then(data => {
      this.setState({
        ...this.state,
        nativeData: data.timeseries,
        nativeTotals: data.totals
      });
    });
  }

  onSubmit(event) {
    event.preventDefault();
    localStorage.setItem("putre-panel-authKey", this.state.authKey);
    this.setState({ ...this.state, loggedIn: true }, () => {
      this.refreshData(defaultViewDays, defaultViewStep);
    });
  }

  onAuthKeyChange(event) {
    this.setState({ ...this.state, authKey: event.target.value });
  }

  onLogout() {
    localStorage.clear();
    this.setState({ ...this.state, loggedIn: false });
  }

  componentDidMount() {
    if (this.state.loggedIn) {
      this.refreshData(defaultViewDays, defaultViewStep);
    }
  }

  render() {
    if (!this.state.loggedIn) {
      return (
        <div style={{ height: "100%", margin: "10em" }}>
          <Grid textAlign="center" verticalAlign="middle">
            <Grid.Column style={{ width: 500 }}>
              <Header as="h2" textAlign="center">
                Enter your auth key to login
              </Header>
              <Form size="large" onSubmit={this.onSubmit}>
                <Segment>
                  <Form.Input
                    fluid
                    icon="lock"
                    iconPosition="left"
                    placeholder="Auth Key"
                    type="text"
                    value={this.state.authKey}
                    onChange={this.onAuthKeyChange}
                  />
                  <Button color="teal" fluid size="large" type="submit">
                    Login
                  </Button>
                </Segment>
              </Form>
            </Grid.Column>
          </Grid>
        </div>
      );
    }
    console.log(this.state.nativeData);
    console.log(this.state.cloudflareData);

    return (
      <div>
        <Container textAlign="right">
          <Menu inverted>
            <Menu.Item>
              <img alt="" src="/favicon.ico" />
            </Menu.Item>
            <Menu.Item onClick={() => this.refreshData(1, 1 / 24)}>
              Last 24 hours
            </Menu.Item>
            <Menu.Item onClick={() => this.refreshData(30, 1)}>
              Last month
            </Menu.Item>
            <Menu.Item onClick={() => this.refreshData(60, 1)}>
              Last 2 months
            </Menu.Item>
            <Menu.Item onClick={() => this.refreshData(120, 1)}>
              Last 4 months
            </Menu.Item>
            <Menu.Item onClick={() => this.refreshData(180, 1)}>
              Last 6 months
            </Menu.Item>
            <Menu.Item onClick={() => this.refreshData(365, 1)}>
              Last year
            </Menu.Item>
            <Menu.Item
              onClick={() =>
                this.refreshData(
                  Math.round(
                    (new Date() - new Date("2016-02-11")) / (1000 * 3600 * 24)
                  ),
                  1
                )
              }
            >
              Since first upload
            </Menu.Item>
            <Menu.Item position="right" onClick={this.onLogout}>
              Logout
            </Menu.Item>
          </Menu>
        </Container>

        <Container>
          <Header
            as="h2"
            content={`Last ${this.state.days} days`}
            style={style.header}
            textAlign="center"
          />
          <Segment>
            <Grid centered>
              <Statistic.Group>
                <Statistic>
                  <Statistic.Value>
                    {this.state.nativeTotals.amount === undefined ? (
                      <Loader active inline />
                    ) : (
                      this.state.nativeTotals.amount
                    )}
                  </Statistic.Value>
                  <Statistic.Label>Uploads</Statistic.Label>
                </Statistic>
                <Statistic>
                  <Statistic.Value>
                    {this.state.nativeTotals.amount === undefined ? (
                      <Loader active inline />
                    ) : (
                      formatSize(this.state.nativeTotals.size)
                    )}
                  </Statistic.Value>
                  <Statistic.Label>Uploaded</Statistic.Label>
                </Statistic>

                {this.state.days <= 365 ? (
                  <Statistic>
                    <Statistic.Value>
                      {this.state.cloudflareTotals.uniques === undefined ? (
                        <Loader active inline />
                      ) : (
                        this.state.cloudflareTotals.uniques.all
                      )}
                    </Statistic.Value>
                    <Statistic.Label>Views</Statistic.Label>
                  </Statistic>
                ) : null}
                {this.state.days <= 365 ? (
                  <Statistic>
                    <Statistic.Value>
                      {this.state.cloudflareTotals.bandwidth === undefined ? (
                        <Loader active inline />
                      ) : (
                        formatSize(this.state.cloudflareTotals.bandwidth.all)
                      )}
                    </Statistic.Value>
                    <Statistic.Label>transfered</Statistic.Label>
                  </Statistic>
                ) : null}
              </Statistic.Group>
            </Grid>
          </Segment>

          {this.state.days <= 365 ? (
            <div>
              <Header
                as="h2"
                content="Cloudflare Stats"
                style={style.header}
                textAlign="center"
              />
              <Segment.Group>
                <Segment>
                  <LineChart
                    data={this.state.cloudflareData}
                    type="size"
                    yLabel="Traffic served"
                    stacked={true}
                    datasets={[
                      {
                        label: "Uncached Bandwidth",
                        dataCallback: datapoint => datapoint.bandwidth.uncached
                      },
                      {
                        label: "Cached Bandwidth",
                        dataCallback: datapoint => datapoint.bandwidth.cached
                      }
                    ]}
                  />
                </Segment>

                <Segment>
                  <LineChart
                    data={this.state.cloudflareData}
                    yLabel="Visits"
                    datasets={[
                      {
                        label: "Unique",
                        dataCallback: datapoint => datapoint.uniques.all
                      }
                    ]}
                  />
                </Segment>

                <Segment>
                  <LineChart
                    data={this.state.cloudflareData}
                    yLabel="Requests"
                    stacked={true}
                    datasets={[
                      {
                        label: "Cached",
                        dataCallback: datapoint => datapoint.requests.cached
                      },
                      {
                        label: "Uncached",
                        dataCallback: datapoint => datapoint.requests.uncached
                      }
                    ]}
                  />
                </Segment>
              </Segment.Group>
            </div>
          ) : null}

          <Header
            as="h2"
            content="Native Stats"
            style={style.header}
            textAlign="center"
          />

          <Segment.Group>
            <Segment>
              <LineChart
                data={this.state.nativeData}
                yLabel="Uploaded files size"
                type="size"
                datasets={[
                  {
                    label: "Uploaded size",
                    dataCallback: datapoint => datapoint.data.size
                  }
                ]}
              />
            </Segment>

            <Segment>
              <LineChart
                data={this.state.nativeData}
                yLabel="Uploaded files count"
                datasets={[
                  {
                    label: "Uploaded count",
                    dataCallback: datapoint => datapoint.data.amount
                  }
                ]}
              />
            </Segment>

            <Segment>
              <LineChart
                data={this.state.nativeData}
                yLabel="Total uploaded files size"
                type="size"
                datasets={[
                  {
                    label: "Total uploaded size",
                    dataCallback: datapoint => datapoint.data.sumSize
                  }
                ]}
              />
            </Segment>

            <Segment>
              <LineChart
                data={this.state.nativeData}
                yLabel="Total uploaded files count"
                datasets={[
                  {
                    label: "Total uploaded count",
                    dataCallback: datapoint => datapoint.data.sumAmount
                  }
                ]}
              />
            </Segment>
          </Segment.Group>
        </Container>
      </div>
    );
  }
}

export default App;
