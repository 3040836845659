import axios from "axios";

// TODO: handle wrong auth key error

export function loadCloudflareStatsData(key, days) {
  return new Promise(resolve => {
    const minutes = days * 24 * 60;

    axios
      .get(`https://api.put.re/stats/cloudflare?since=-${minutes}`, {
        headers: {
          authorization: key
        }
      })
      .then(response => {
        const data = response.data.data.timeseries.map(item => {
          return {
            item: item,
            x: new Date(item.since)
          };
        });

        resolve({
          timeseries: data,
          totals: response.data.data.totals
        });
      });
  });
}

export function loadNativeStatsData(key, days, stepDays) {
  return new Promise(resolve => {
    const minutes = days * 24 * 60;
    const step = 1000 * 3600 * 24 * stepDays;

    axios
      .get(
        `https://api.put.re/stats/native?since=-${Math.round(
          minutes
        )}&step=${Math.round(step)}`,
        {
          headers: {
            authorization: key
          }
        }
      )
      .then(response => {
        const data = response.data.data.timeseries.map(item => {
          return {
            item: item,
            x: new Date(item.since)
          };
        });

        resolve({
          timeseries: data,
          totals: response.data.data.totals
        });
      });
  });
}
